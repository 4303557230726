<template>
  <UraianRjppForm mode="Ubah" module="Uraian RJPP"> </UraianRjppForm>
</template>

<script>
import UraianRjppForm from './form';

const UraianUpdateRjpp = {
  name: 'UraianUpdateRjpp',
  components: { UraianRjppForm },
};

export default UraianUpdateRjpp;
</script>
