<template>
  <sdPageHeader :title="title"> </sdPageHeader>
  <Main>
    <HorizontalFormStyleWrap>
      <sdCards headless>
        <a-form
          layout="horizontal"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :rules="rules"
          @finish="handleSubmit"
        >
          <a-form-item name="uraian" label="Uraian RJPP">
            <a-input v-model:value="formState.uraian" placeholder="Masukkan uraian RJPP" />
          </a-form-item>

          <a-form-item name="description" label="Deskripsi">
            <a-input v-model:value="formState.description" placeholder="Masukkan deskripsi RJPP" />
          </a-form-item>
          <a-row>
            <a-col
              style="margin-bottom:12px;"
              :lg="{ span: 20, offset: 4 }"
              :md="{ span: 15, offset: 9 }"
              :xs="{ span: 24, offset: 0 }"
            >
              <h3>Progress Uraian RJPP</h3>
            </a-col>
          </a-row>
          <a-row>
            <a-col v-for="(prog, index) in formState.rjpp_progress" :key="index" :span="24">
              <a-row v-if="index > 0">
                <a-col
                  style="margin-bottom:12px;"
                  :lg="{ span: 20, offset: 4 }"
                  :md="{ span: 15, offset: 9 }"
                  :xs="{ span: 24, offset: 0 }"
                >
                  <h3>* new item</h3>
                </a-col>
              </a-row>
              <a-form-item name="tahun" label="Tahun">
                <a-input-number style="width: 50%;" v-model:value="prog.tahun" placeholder="Masukkan tahun" />
                <a-button type="dashed" size="large" style="margin-left:8px;" @click="myFun.hapusItemProgress(index)">
                  Hapus Item Progress
                </a-button>
              </a-form-item>
              <a-form-item name="keterangan" label="Keterangan">
                <a-input v-model:value="prog.keterangan" placeholder="Masukkan keterangan uraian" />
              </a-form-item>
              <a-form-item label="Progress Triwulan">
                <a-row>
                  <a-col :span="6">
                    <a-checkbox v-model:checked="prog.tw1">TW1</a-checkbox>
                  </a-col>
                  <a-col :span="6">
                    <a-checkbox v-model:checked="prog.tw2">TW2</a-checkbox>
                  </a-col>
                  <a-col :span="6">
                    <a-checkbox v-model:checked="prog.tw3">TW3</a-checkbox>
                  </a-col>
                  <a-col :span="6">
                    <a-checkbox v-model:checked="prog.tw4">TW4</a-checkbox>
                  </a-col>
                </a-row>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :lg="{ span: 20, offset: 4 }" :md="{ span: 15, offset: 9 }" :xs="{ span: 24, offset: 0 }">
              <a-form-item>
                <a-button type="dashed" style="width: 36%" size="large" @click="myFun.tambahItemProgress()">
                  Tambah Item Progress
                </a-button>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row style="margin-bottonm:12px;">
            <a-col :lg="{ span: 20, offset: 4 }" :md="{ span: 15, offset: 9 }" :xs="{ span: 24, offset: 0 }">
              <div class="sDash_form-action">
                <sdButton class="sDash_form-action__btn" type="primary" size="large" htmlType="submit">
                  {{ isLoading ? 'Harap Menunggu...' : mode }}
                </sdButton>

                <router-link to="/laporan-manajemen/progress-rjpp">
                  <sdButton class="sDash_form-action__btn" type="light" size="large" htmlType="button">
                    Batal
                  </sdButton>
                </router-link>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </sdCards>
    </HorizontalFormStyleWrap>
  </Main>
</template>

<script>
import { HorizontalFormStyleWrap } from '../../../../components/crud/style';
import { Main } from '../../../styled';
import { useStore } from 'vuex';
import { reactive, computed, onMounted, toRaw } from 'vue';
import { useRoute } from 'vue-router';

const UraianRjppForm = {
  name: 'UraianRjppForm',
  components: { HorizontalFormStyleWrap, Main },
  data() {
    return {
      title: this.mode + ' ' + this.module,
    };
  },
  props: ['mode', 'module'],
  setup(props) {
    const { state, dispatch } = useStore();
    const { params } = useRoute();
    const isLoading = computed(() => state.crud.loading);
    const crud = computed(() => state.crud.data);

    class Progress {
      id = null;
      tahun = new Date().getFullYear();
      keterangan;
      tw1 = false;
      tw2 = false;
      tw3 = false;
      tw4 = false;
    }

    const formState = reactive({
      id: null,
      uraian: '',
      description: '',
      rjpp_progress: [new Progress()],
      remove_progress: [],
    });

    const labelCol = {
      lg: 4,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 20,
      md: 15,
      xs: 24,
    };

    const handleSubmit = () => {
      let dataParams = toRaw(formState);
      console.log(dataParams);
      // return false;

      // eslint-disable-next-line no-unreachable
      if (props.mode == 'Tambah') {
        dispatch('axiosCrudSubmitData', {
          url: 'laporan-progress-rjpp-uraian',
          data: dataParams,
        });
      } else if (props.mode == 'Ubah') {
        dispatch('axiosDataUpdate', {
          id: params.itemId,
          url: 'laporan-progress-rjpp-uraian',
          data: dataParams,
        });
      }
    };

    const rules = {
      rjpp: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
    };

    const options = reactive({
      parents: [],
    });

    const filterSelect = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    // tambah dan hapus uraian
    class Fun {
      tambahItemProgress = () => {
        formState.rjpp_progress.push(new Progress());
      };
      hapusItemProgress = index => {
        if (formState.rjpp_progress[index].id != null) {
          formState.remove_progress.push(formState.rjpp_progress[index].id);
        }
        formState.rjpp_progress.splice(index, 1);
      };
    }

    const myFun = new Fun();

    onMounted(() => {
      if (props.mode == 'Ubah') {
        const data = {
          url: 'laporan-progress-rjpp-uraian',
          id: params.itemId,
        };

        dispatch('axiosSingleDataGet', data).then(() => {
          console.log(crud.value);
          formState.id = crud.value.id;
          formState.uraian = crud.value.uraian;
          formState.description = crud.value.description;
          formState.rjpp_progress = crud.value.rjpp_progress;
        });
      }
    });

    return {
      formState,
      labelCol,
      wrapperCol,
      handleSubmit,
      isLoading,
      rules,
      options,
      filterSelect,
      myFun,
    };
  },
};

export default UraianRjppForm;
</script>
